import { css } from "@emotion/css";
import { Button, Card, Input, Modal, Select, Space } from "antd";
import { useState } from "react";

const Grouping: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [groupNumber, setGroupNumber] = useState(0);
    const [source, setSource] = useState<string>("");
    
    return <Card title="Grouping">
        <div className={groupingStyle}>
            <Input.TextArea 
                onChange={e => setSource(e.target.value)}
                placeholder="Add texts to group. Each line will be regarded as an entry."/>
            
            <div className={css`width: 100%`}></div>

            <Space.Compact>
                <Select options={getGroupingOptions(10)} onSelect={str => setGroupNumber(Number(str))}></Select>
                <Button onClick={() => setShowModal(true)} disabled={groupNumber === 0}>Grouping</Button>
            </Space.Compact>
        </div>

        {showModal ? 
            <Modal open={showModal} onCancel={() => setShowModal(false)} onOk={() => setShowModal(false)}>
                <Card title="Result">
                    {displayShuffledResult(shuffleGroup(source.split('\n'), groupNumber))}
                </Card>
            </Modal>
        : null}
        
    </Card>;
};

function getGroupingOptions(number: number) {
    let result = [];
    for (let i = 0; i < number; i++) {
        result.push({
            value: i + 1,
            label: i + 1,
        });
    }
    return result;
}

function shuffleGroup(source: string[], groupNumber: number) {
    let result = [];
    let shuffled = source.sort(() => Math.random() - 0.5);

    for (let i = 0; i < groupNumber; i++) {
        result.push(shuffled.slice(i * shuffled.length / groupNumber, (i + 1) * shuffled.length / groupNumber));
    }

    return result;
}

function displayShuffledResult(source: string[][]) {
    return source.map((group, index) => <div>
        <div>Group {index + 1}</div>
        <div>{group.join(", ")}</div>
    </div>);
}

const groupingStyle = css`
    display: flex;
    flex-direction: column;

    textarea {
        margin: 8px 0;
        min-height: 200px;
    }
`;

export default Grouping;