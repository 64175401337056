import { Card, List } from "antd";
import { Alert } from "./alert";

import "./index.css";

const educationBackgroundList = [
    {
        image: "img/hku.jpg",
        header: "The University of Hong Kong 香港大学",
        description: `<p class="education-type">Master of Science in Computer Science (General Stream)</p>` +
        `<p>Graduated in Aug 2021 in Project Mode with 3.73/4.30 and Passed with Honour.</p>`
    },
    {
        image: "img/cityu.jpeg",
        header: "City University of Hong Kong 香港城市大学",
        description: '<p class="education-type">Bachelor of Science in Computer Science</p>' +
        `<p>Graduated in July, 2020 with GPA 3.93/4.30 and the First Honor classification.</p>`,
    },
    {
        image: "img/tianli.jpg",
        header: "Luzhou Tianli School 泸州天立学校",
        description: '<p class="education-type">High School Diploma</p>' +
        `<p>Graduated in June, 2016 with NUEE Score 656 (Top 0.57%).</p>`,
    },
];

const workingExperienceList = [
    {
        image: "img/miotech.jpg",
        header: "MioTech. 妙盈科技有限公司",
        description: '<p class="education-type">Senior Software Developer in Back-end</p>' +
        `<p>Full-time employee. Previously working on a to-C project; currently supporting on to-B projects.</p>`,
    },
    {
        image: "img/hkex-new.png",
        header: "Hong Kong Exchange and Clearing Ltd. 香港交易所",
        description: '<p class="education-type">Assistant Analyst Programmer</p>' +
        `<p>Full-time internship taken place in Year 2018 Summer, lasted for 9 months.</p>`,
    },
];

const interestItems = [
    {
        genre: "Music",
        items: [
            "CityU Choir Joined in Year 2016/17 as Tenor I, in Year 2019/20 as Bass II.",
            "Hong Kong Youth Choir Sang for the Christmas project in Year 2020 as Bass.",
        ]
    },
    {
        genre: "Gaming",
        items: [
            "Strong interests in RPG games.",
            "+: MOBA, Action, Strategy; -: FPS, Sports",
        ],
    },
    {
        genre: "Sporting",
        items: [
            "Archery",
            "Badminton",
        ],
    }
];


// create homepage component
export function Home() {
    // create a container for the main page
    return (<>
        <Alert title="Notice"/>
        <div className="container">
            <Card title="Welcome!" className="card">
                <p>Richard is currently working in Shanghai as a Software Developer in Back-end.</p>
                <p>Having tried in various approaches, he wants to be more keen on newest technologies and gets himself prepared for the ever changing job market.</p>
                <p>He may stay in Shanghai for the following years; he may choose to go to foreign lands for more open insights. He hopes the covid would be overcome soon.</p>
                <p>Click here for more information about the learning experience for Richard there.</p>
                <p>Last Modified Date: 2022 Feb 13</p>
            </Card>

            <Card title="Educational Background" className="card">
                {educationBackgroundList.map((educationBackground) => {
                    return loadImageWithDesciption(educationBackground.image, educationBackground.header, educationBackground.description);
                })}
            </Card>

            <Card title="Working Experience" className="card">
                {workingExperienceList.map((workingExperience) => {
                    return loadImageWithDesciption(workingExperience.image, workingExperience.header, workingExperience.description);
                })}
            </Card>

            <Card title="Personal Interests" className="card">
                <List>
                    {interestItems.map((interestItem) => {
                        return loadInterestDescription(interestItem.genre, interestItem.items);
                    })}
                </List>
            </Card>
        </div>
        </>
    );
}

function loadImageWithDesciption(image: string, header: string, description: string) {
    return (
        <div className="image-card">
            <div className='image-container'>
                <img src={process.env.PUBLIC_URL + image} alt={header} />
            </div>
            <div className='description-container'>
                <p>{header}</p>
                <div dangerouslySetInnerHTML={{__html: description}}></div>
            </div>
        </div>
    );
}

function loadInterestDescription(genre: string, items: string[]) {
    return <List.Item style={interestLv1Style}>
        <div className="interest-genre">{genre}</div>
        <div>
            <List>
                {items.map((item) => {
                    return <List.Item>{item}</List.Item>
                })}
            </List>
        </div>
    </List.Item>
};

const interestLv1Style = {
    "display": "flex",
    "justify-content": "flex-start",
};