import { css } from "@emotion/css";
import { Menu } from "antd";

// create a common navigation bar
export function Nav() {
    return (
        <Menu className={navStyle}>
            <Menu.Item><a href="/">Home</a></Menu.Item>
            <Menu.Item><a href="/about">About</a></Menu.Item>
            {/* <Menu.Item><a href="/contact">Contact</a></Menu.Item> */}
            <Menu.Item><a href="/tools">Tools</a></Menu.Item>
        </Menu>
    );
}

/*
function isCurrentPath(path: string) {
    return window.location.pathname === path;
}
*/

const navStyle = css`
    background: #999;
    display: flex;

    min-height: 50px;
    align-items: center;

    padding-left: 45px;

    li {
        max-width: 120px;
        text-align: center;
    }

    a {
        margin: 0 10px;
    }
`;