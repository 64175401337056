import { Card } from "antd";
import { useState } from "react";
import Grouping from "./Grouping";
import What2Eat from "./What2Eat";

const toolTabList = [
    {
        key: 'what2eat',
        label: 'What to Eat',
    },
    {
        key: 'group',
        label: 'Group',
    }
]

const componentList: Record<string, React.ReactNode> = {
    what2eat: <What2Eat/>,
    group: <Grouping/>,
};

export function ToolLayout() {
    const [tab, setTab] = useState("what2eat");

    return (
        <Card tabList={toolTabList} onTabChange={setTab}>
            {componentList[tab]}
        </Card>
    );
}