import { css } from "@emotion/css";
import { Button, Card, Input, List, Space } from "antd";
import { useState } from "react";

const What2Eat : React.FC = () => {
    const [selectionList, setSelectionList] = useState<string[]>([]);
    const [currentInput, setCurrentInput] = useState<string>("");
    const [randomized, setRandomized] = useState<string>("");

    return <>
        <Card title="What to Eat?" className={cardStyle}>
            <div>
                <Space.Compact className={css`width: 70%`}>
                    <Input placeholder="Add a selection" onChange={e => setCurrentInput(e.target.value)}></Input>
                    <Button onClick={() => setSelectionList([...selectionList, currentInput])}>Add</Button>
                </Space.Compact>

                <Space.Compact>
                    <Button onClick={() => {setSelectionList([]); setRandomized("");}}>Clear</Button>
                    <Button onClick={() => setRandomized(randomize(selectionList))}>Random</Button>
                </Space.Compact>
            </div>

            {selectionList.length > 0 ? <div className={css`width: 100%`}></div> : null}

            <div>
                <List className="food-list">{selectionList.map(item => <List.Item>{item}</List.Item>)}</List>
                <div className={resultStyle}>
                    {randomized ? `Your Recommondation Today: ${randomized}`: ""}
                </div>
            </div>
        </Card>
    </>;
};

const cardStyle = css`
    .ant-card-body div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        margin: 4px 0;

        .ant-list {
            width: 50%;
        }

        .ant-spin-container {
            flex-direction: column;
        }
    }
`;

const resultStyle = css`
    align-self: center;
    margin: 0 auto;
`;

function randomize(source: string[]) {
    return source[Math.floor(Math.random() * source.length)];
};

export default What2Eat;