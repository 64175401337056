import { Modal } from "antd";
import { Component } from "react";

export class Alert extends Component<{title: string}, {show: boolean}> {
    constructor(props: {title: string}) {
        super(props);
        this.state = {
            show: true,
        };
    }
    
    render() {
        return (
            <Modal title={this.props.title}
                open={this.state.show}
                onCancel={() => {this.setState({show: false});}}
                onOk={() => {this.setState({show: false});}}
            >
                This website is still under construction. Please noted that currently it is not the final display.
            </Modal>
        );
    }
};